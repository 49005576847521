import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import LinkArrowSvg from "../../images/link-arrow";

const LifeAtOctet = () => {
  const content = [
    {
      title: "Explorers",
      content:
        "We always await to start a new expedition to learn as we create awesomeness.",
    },
    {
      title: "Planners",
      content:
        "We create thoughtful and holistic plans with your business goals in mind.",
    },
    {
      title: "Creators",
      content: "We conduct market research and craft creative solutions.",
    },
    {
      title: "Transformers",
      content:
        "We build stunning interfaces and turn them into user-centric products.",
    },
  ];
  const dynamicImages = [
    {
      title: "Team Engagement",
      images: ["team-image-3.webp"],
      aspectRatio: "1.258 / 1",
    },
    {
      title: "Team Engagement",
      images: ["team-image-71.webp"],
      aspectRatio: "1.0339 / 1",
    },
    {
      title: "Team Engagement",
      images: ["team-image-72.webp"],
      aspectRatio: "1.258 / 1",
    },
  ];

  const imageData = useStaticQuery(graphql`
    query {
      dynamicImages: allFile(
        filter: { relativePath: { regex: "/^(team-image|visa)/" } }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const imagesByPath = {};
  imageData.dynamicImages.edges.forEach(({ node }) => {
    const imagePath = node.relativePath;
    const imageData = getImage(node.childImageSharp);
    imagesByPath[imagePath] = imageData;
  });
  return (
    <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#FAFBFB] w-[100%] relative overflow-hidden life-octet">
      {/* <StaticImage placeholder="none" src='../images/guides-colaboration.webp' alt='guides' className='guides-collaboration top-[-50px] right-0 md:top-[unset] w-[145%] lg:w-[auto] bottom-[unset] lg:bottom-[unset] md:bottom-0 lg:top-[0] lg:right-[0] md:right-[-250px] absolute' /> */}
      <div className="container">
        <h2 className="lg:mb-[100px] md:mb-[80px] mb-[60px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[70%]">
          Life at <br /> Octet Design Studio
        </h2>
        <div className="team-div mx-[-20px] md:mx-[calc((100vw-768px)/-2)] lg:mx-[calc((100vw-1166px)/-2)] items-center mb-[60px] md:mb-[100px] pl-[0]">
          <Swiper
            className="team-slider cursor-[url(../images/drag.webp),_move]"
            breakpoints={{
              300: {
                spaceBetween: 0,
                slidesPerView: "auto",
              },
              768: {
                spaceBetween: 0,
                slidesPerView: "auto",
              },
              1024: {
                spaceBetween: 0,
                slidesPerView: "auto",
              },
              1200: {
                spaceBetween: 2,
                slidesPerView: "auto",
              },
            }}
          >
            {dynamicImages.map(({ title, images, aspectRatio }, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="!flex flex-col justify-between"
                >
                  <GatsbyImage
                    className="slider-image"
                    style={{ aspectRatio: aspectRatio }}
                    image={imagesByPath[images[0]]}
                    alt={title ? title : "octet life"}
                    title={title}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="flex flex-col md:flex-row lg:gap-[60px] flex-wrap lg:flex-nowrap md:gap-x-[60px] md:gap-y-[40px] gap-[40px] mb-[50px] lg:mb-[60px]">
          {content.map((data, index) => (
            <div
              key={index}
              className="lg:max-w-[calc(25%-45px)] md:max-w-[calc(50%-30px)] pr-[10px] lg:pr-[0]"
            >
              <h3 className="capitalize mb-[10px] leading-[27px] font-medium font-heading lg:leading-[55px] text-[20px] md:text-[28px] lg:text-[35px] md:leading-[40px]">
                {data.title}
              </h3>
              <p className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] lg:leading-[30px] tracking-[.02em] md:tracking-[.03em]">
                {data.content}
              </p>
            </div>
          ))}
        </div>
        <StaticImage
          placeholder="none"
          width={303}
          src="../../images/glassdoor.webp"
          alt="glassdoor"
          className="md:w-[150px] w-[90px] md:mb-[30px] mb-[20px]"
        />
        <Link
          to="/careers/"
          title="Careers"
          className="capitalize  join-link w-fit font-body text-[12px] lg:text-[15px] leading-[1.2] tracking-[.03em] flex items-center hover:font-medium gap-[9px]"
        >
          JOIN OUR TEAM{" "}
          <span className="rotate-45">
            <LinkArrowSvg size={15.58} fill="#2A2A2A" />
          </span>
        </Link>
      </div>
    </section>
  );
};
export default LifeAtOctet;
