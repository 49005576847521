import React from "react";
import Banner from "../components/Banner";
import OurProcess from "../components/about/OurProcess";
import WhyOctet from "../components/about/WhyOctet";
import WorkCollaboration from "../components/about/WorkCollaboration";
import LifeAtOctet from "../components/about/LifeAtOctet";
import AboutOctet from "../components/about/AboutOctet";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
// import { Helmet } from "react-helmet";
// import { StaticImage } from "gatsby-plugin-image";

const About = () => {
  const banner = {
    title: `<span class="h1-span">Strategic </span><span class="h1-span"> UX Consultants</span>`,
    content:
      "We transform SaaS, Enterprise and B2B products with a collaborative and transparent approach to deliver an impeccable combination of design and usability. ",
  };
  const content = [
    {
      title: "Business Analysts",
      content:
        "Our analysts understand your business, conduct market research and foresee business challenges to provide cost-effective solutions. ",
    },
    {
      title: "Designers",
      content:
        "Our experienced designers craft digital offerings with eye-catching elements that improve your user experience and engagement.",
    },
    {
      title: "Developers",
      content:
        "Our developers prioritise heuristics and efficiently transform designs into pixel-perfect UIs using suitable technology stacks and libraries.",
    },
  ];
  const title = `Solving Complex Problems <br /> in Collaboration`;
  return (
    <Layout>
      <div className="about">
        <Banner content={banner} page={"about"} />
        <AboutOctet />
        <WhyOctet />
        <WorkCollaboration content={content} title={title} />
        <OurProcess />
        <LifeAtOctet />
        {/* <KnowMoreSection nin={["About", "Contact Us"]} Include="Services" /> */}
        {/* <StaticImage src="../images/octet-logo.webp" /> */}
      </div>
    </Layout>
  );
};

export default About;

export const Head = () => (
  <>
    <Seo
      title="About Us | Octet Design Studio | Empowering Success Together"
      description="Are you looking for a UI UX design agency that specialises in product design and work towards growth? You are at the right place. Contact us to get started!"
    />
    {/* <Helmet>
      <meta property="og:image" content={"https://octet.design/og-image/octet-logo.webp"} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="50" />
    </Helmet> */}
  </>
);
