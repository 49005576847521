import React from 'react';
import { Link } from "gatsby";
import LinkArrowSvg from "../../images/link-arrow";
import { StaticImage } from 'gatsby-plugin-image';

const OurProcess = () => {  
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px]">
            <div className='container'>
                <div className='lg:mb-[103px] md:mb-[80px] mb-[62px]'>
                    <h2 className='lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]'>Our Process</h2>
                    <p className='lg:text-[18px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] mb-[22px] md:mb-[20px] lg:mb-[30px] md:w-[668px] lg:w-[800px] pr-[10px] md:pr-[0]'>
                        We focus on the flexible, lean and agile UI UX process that aligns your business goals with end-user needs. From ideation to implementation, we ensure that our process proves efficient throughout the design journey and achieves unparalleled growth.
                    </p>
                    <Link to={'/process/'} title='Read More AbOUT Process' className="md:mt-[40px]  mt-[20px] lg:mt-[10.5px] font-body service-link transition-all hover:duration-300 duration-300 text-[15px] hover:font-medium leading-[1.2] tracking-[.03em] uppercase flex items-center gap-[9px] hover:gap-[20px]">
                        Explore the Process <span className='rotate-45'><LinkArrowSvg size={15.58} fill='#2A2A2A' /></span>
                    </Link>
                </div>
                <StaticImage placeholder="none" src='../../images/process.webp' alt='UI UX Process' title='Process' />
            </div>
        </section>
    )
}
export default OurProcess
