import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const WhyOctet = () => {
    const whyOctet = [
        {
            title: 'Ethical',
            content: 'We prioritise ethical practices and create a positive impact on your business.'
        },
        {
            title: 'Transparent',
            content: 'We provide all the information that concerns your projects without any filter.'
        },
        {
            title: 'Trustworthy',
            content: 'We honour confidentiality and safeguard the sensitive information of your business.'
        },
        {
            title: 'Committed',
            content: 'We deliver high-quality and innovative solutions while also meeting deadlines.'
        }
    ]  
    return (
        <section className="lg:py-[200px] md:py-[120px] py-[80px]">
            <div className='container'>
                <div className='lg:mb-[103px] md:mb-[80px] mb-[62px]'>
                    <h2 className='lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]'>Why Octet?</h2>
                    <p className='lg:text-[18px] md:text-[16px] teact-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] mb-[22px] md:mb-[20px] lg:mb-[30px] md:w-[668px] lg:w-[800px] pr-[10px] md:pr-[0]'>
                    We empower businesses to move towards a transformative journey and unlock their true potential with our experience and industry expertise. Through our transparent and collaborative approach, we provide cutting-edge solutions to assist your business with sustained growth. 
                    </p>
                </div>
                <div className='flex md:gap-[50px] gap-[40px] flex-col lg:flex-row lg:gap-[70px]'>
                    <div className='lg:w-[500px] w-[100%] lg:min-w-[500px]'>
                        <StaticImage alt="Team Outing" title='Team Outing' placeholder="none" src='../../images/team-outing-about.webp' />
                    </div>
                    <div className='flex gap-[40px] md:gap-[50px] flex-col lg:flex-row flex-wrap'>
                        {whyOctet.map(({title, content}, index) => (
                            <div className=' w-[100%] lg:w-[calc(50%-25px)]' key={index}>
                                <h3 className='mb-[10px] leading-[27px] font-medium font-heading lg:leading-[55px] text-[20px] md:text-[28px] lg:text-[35px] md:leading-[40px]' >{title}</h3>
                                <p className='pr-[10px] md:pr-[0] text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] lg:leading-[30px] tracking-[.02em] md:tracking-[.03em]'>{content}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WhyOctet
