import React, {useState, useEffect, useRef} from 'react';
import { StaticImage } from "gatsby-plugin-image";
import ReactPlayer from 'react-player';
import VideoFormat from '../../videos/about-us.mp4'

const AboutOctet = () => {
    const playerRef = useRef(null);

    useEffect(() => {
      if (playerRef && playerRef.current) {
        const player = playerRef.current.getInternalPlayer();
        if (player) {
          player.play().catch(error => {
            console.error('Autoplay was prevented:', error.message);
          });
        }
      }
    }, []);
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            const isTabletQuery = window.matchMedia('(max-width: 1199px)');
            setIsTablet(isTabletQuery.matches);
            const isMobileQuery = window.matchMedia('(max-width: 767px)');
            setIsMobile(isMobileQuery.matches);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <section className="about-octet lg:py-[200px] md:py-[120px] py-[80px] bg-[#FAFBFB]">
            <div className="container">
                <h2 className="lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] lg:max-w-[70%]">Our Modern Approach</h2>
                <p className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] lg:w-[666px] lg:mb-[100px] md:mb-[80px] mb-[60px]">
                    We are a leading UI UX Design Agency having expertise to simplify complex products into more simple yet modern designs with our unique ideas and prudent, proactive and professional approach.
                </p>
                <div className={` ${isTablet ? 'lg:gap-[30px]' : 'lg:gap-[0]'} flex justify-between flex-col lg:flex-row gap-[50px]`}>
                    <div className="md:w-[501px] w-[100%] lg:max-w-[501px] relative">
                        <StaticImage placeholder="none" src="../../images/about-octet-guide.webp" alt="guides" />
                        {/* <StaticImage placeholder="none" className="!absolute md:w-[196px] w-[120px] top-[calc(50%+10px)] left-[calc(50%+8px)] translate-y-[-50%] translate-x-[-50%]" src="../../images/about/-logo.webp" alt="about logo" /> */}
                        <ReactPlayer
                            ref={playerRef}
                            url={VideoFormat}
                            className={`!absolute md:w-[196px] w-[120px] top-[calc(50%)] left-[calc(50%)] translate-y-[-50%] translate-x-[-50%]`}
                            height={isTablet ? isMobile ? 300 : 490 : 675}
                            width="100%"
                            // controls={true}
                            playing={true}
                            loop
                        />
                    </div>
                    <div className="flex flex-col gap-[40px] lg:max-w-[50.5%]">
                        <div>
                            <h3 className="lg:w-[591px] mb-[10px] md:text-[28px] font-medium text-[20px] leading-[27px] md:leading-[40px] lg:pr-[0] md:pr-[40px] pr-[10px] lg:text-[30px] lg:max-w-[60%] lg:leading-[40px] font-heading">
                                Symmetry
                            </h3>
                            <p className={`leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] ${isTablet ? 'lg:w-fit' : 'lg:w-[591px]'}`}>
                                We emphasize symmetry and pleasing proportion to offer seamless navigation, clear communication and a flow that is visually appealing to users.
                            </p>
                        </div>
                        <div>
                            <h3 className="lg:w-[591px] mb-[10px] md:text-[28px] font-medium text-[20px] leading-[27px] md:leading-[40px] lg:pr-[0] md:pr-[40px] pr-[10px] lg:text-[30px] lg:max-w-[60%] lg:leading-[40px] font-heading">
                                Harmony
                            </h3>
                            <p className={`leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] ${isTablet ? 'lg:w-fit' : 'lg:w-[591px]'}`}>
                                We create a balance between elements to form a cohesive design system to provide a pleasing experience to your customers.
                            </p>
                        </div>
                        <div>
                            <h3 className="lg:w-[591px] mb-[10px] md:text-[28px] font-medium text-[20px] leading-[27px] md:leading-[40px] lg:pr-[0] md:pr-[40px] pr-[10px] lg:text-[30px] lg:max-w-[60%] lg:leading-[40px] font-heading">
                                Hierarchy
                            </h3>
                            <p className={`leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] ${isTablet ? 'lg:w-fit' : 'lg:w-[591px]'}`}>
                                We focus on the overall structure of the system and the relationship between elements for easy scanability of our design process.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutOctet
